import { FC, useMemo } from 'react';

import { Link } from '@common/atoms/Link/Link';
import { ModuleScheduleItem } from '@common/clients/api';
import { BettingDisclaimer } from '@web/atoms/BettingDisclaimer';
import { BettingIntegrationTracker } from '@web/atoms/BettingIntegrationTracker';
import { BookmakerLogo } from '@web/atoms/BookmakerLogo/BookmakerLogo';
import { TagElement } from '@web/atoms/TagElement';

import { ArrowRight } from '../NotificationBar/NotificationBar';

import styles from './ArticlePromoItem.module.scss';

export interface Props {
    moduleScheduleItem: ModuleScheduleItem;
    hasDarkBackground?: boolean;
    isCommentItem?: boolean;
    showPromoOnHome?: boolean;
}

export const ArticlePromoItem: FC<Props> = ({
    moduleScheduleItem,
    hasDarkBackground,
    isCommentItem,
    showPromoOnHome,
}) => {
    const url = moduleScheduleItem.url || moduleScheduleItem.bookmaker?.url;
    const { bookmaker } = moduleScheduleItem;
    const icon = hasDarkBackground
        ? moduleScheduleItem.bookmaker?.darkBackgroundIcon
        : moduleScheduleItem.bookmaker?.lightBackgroundIcon;

    const itemClasses = [styles.ArticlePromoItem];
    if (isCommentItem) itemClasses.push(styles['comment-item']);
    if (showPromoOnHome) itemClasses.push(styles['promoted-item']);

    const ItemBookmarkerLogo: JSX.Element = useMemo(
        () => (
            <div className={styles['logo-wrapper']}>
                <BookmakerLogo
                    className={styles['bookmaker-logo']}
                    alt={moduleScheduleItem.bookmaker?.name || ''}
                    url={url}
                    src={icon ?? ''}
                />
            </div>
        ),
        [icon, moduleScheduleItem.bookmaker?.name, url],
    );
    const backgroundColor = bookmaker?.backgroundColor;

    return (
        <div
            className={itemClasses.join(' ')}
            style={backgroundColor ? ({ '---backgroundColor': backgroundColor } as React.CSSProperties) : {}}
        >
            {isCommentItem && moduleScheduleItem.bookmaker ? (
                <div className={styles['betting-provider-logo']}>
                    <BookmakerLogo
                        className={styles['bookmaker-logo']}
                        alt={moduleScheduleItem.bookmaker?.name || ''}
                        url={url}
                        src={moduleScheduleItem.bookmaker?.darkBackgroundIcon ?? ''}
                    />
                </div>
            ) : null}
            <div
                className={`${styles['article-promo-item']} article-promo-item ndm-betting-integration-tracker`}
            >
                <div className={styles['article-container']} data-tracker-name={moduleScheduleItem.module}>
                    {showPromoOnHome ? (
                        <Link href={url ?? ''} isSponsored>
                            <ArrowRight />
                        </Link>
                    ) : null}
                    <div className={styles.content}>
                        {moduleScheduleItem.tag ? (
                            <Link href={url ?? ''} isSponsored>
                                <TagElement item={moduleScheduleItem ?? undefined} />
                            </Link>
                        ) : (
                            !isCommentItem && ItemBookmarkerLogo
                        )}
                        <Link href={url ?? ''} isSponsored>
                            <h4>{moduleScheduleItem.text}</h4>
                        </Link>
                    </div>
                    {moduleScheduleItem.tag && !isCommentItem ? ItemBookmarkerLogo : null}
                </div>
                {moduleScheduleItem && !showPromoOnHome ? <BettingDisclaimer showEighteenPlusLogo /> : null}
            </div>
            {bookmaker?.id ? (
                <BettingIntegrationTracker bookmakerID={bookmaker?.id} module={moduleScheduleItem.module} />
            ) : null}
        </div>
    );
};
