import { ReactElement } from 'react';

import { SportDataMatch, ThreeWayOdds } from '@common/clients/api';
import { BookmakerLogo } from '@web/atoms/BookmakerLogo';

import { Odd } from '../Odd';

import styles from '../../OddsBlock.module.scss';

interface Props {
    odds: ThreeWayOdds;
    match: SportDataMatch;
    isRectangleBlock?: boolean;
    hasDarkBackground?: boolean;
    hideDisclaimer?: boolean;
    hasLimitedSpace?: boolean;
    isCompact?: boolean;
    hide18PlusLogo?: boolean;
    isLarger?: boolean;
    isMatchOfTheDay?: boolean;
}

export const OddGroup = (props: Props): ReactElement => {
    const keys = props.isRectangleBlock ? (['home', 'away'] as const) : (['home', 'draw', 'away'] as const);
    const classes: string[] = [styles['odd-group']];
    const dataItems: { [key: string]: string } = {};
    if (props.odds.bookmaker?.id) {
        classes.push('ndm-betting-integration-tracker');
        dataItems['data-tracker-name'] = 'oddMatchProviderId-' + props.odds.bookmaker.id;
        dataItems['data-tracker-separate'] = '';
    }

    return (
        <ul className={classes.join(' ')} {...dataItems}>
            <li className={styles['logo-wrapper']}>
                <BookmakerLogo
                    alt={props.odds.bookmaker?.name || ''}
                    url={props.odds.bookmaker?.url || ''}
                    src={
                        (props.hasDarkBackground
                            ? props.odds.bookmaker?.darkBackgroundIcon
                            : props.odds.bookmaker?.lightBackgroundIcon) || ''
                    }
                />
            </li>
            {keys.map((key) => {
                return <Odd key={key} oddKey={key} url={props.odds.url} value={props.odds[key]} />;
            })}
        </ul>
    );
};
