import { FC } from 'react';

import { Link } from '@common/atoms/Link';

import styles from '../../OddsBlock.module.scss';

interface Props {
    oddKey: 'home' | 'draw' | 'away';
    value: number;
    url?: string;
}

export const Odd: FC<Props> = ({ oddKey, value, url }) => {
    // We need to map the keys to show 1, x, 2
    const keyMap: { [key: string]: string } = {
        home: '1',
        draw: 'x',
        away: '2',
    };

    const key = keyMap[oddKey] ?? '';
    return (
        <li>
            <span className={styles['odd-key'] + ' odd-' + key}>{key}</span>
            <Link href={url || ''}>
                <a rel="sponsored nofollow" target="_blank" className={styles['odd-value']}>
                    {Number(value).toFixed(2)}
                </a>
            </Link>
        </li>
    );
};
