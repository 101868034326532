import { FC, useEffect, useRef, useState } from 'react';
import { sendGAEvent } from '@next/third-parties/google';

import { useContextData } from '@common/useContextData';

import { RunningExperiments } from '../Experiment';

const GA_EVENT_CATEGORY = 'nēxt_experiments';
const GA_EVENT_NAME = 'next_experiment_shown';

export interface Props {
    type: RunningExperiments;
}

export const Pixel: FC<Props> = ({ type }) => {
    const [hasTriggered, setHasTriggered] = useState(false);
    const { experimentGroup } = useContextData();
    const ref = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const current = ref.current;
        if (hasTriggered || !current) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !hasTriggered) {
                    setHasTriggered(true);
                    sendGAEvent('event', 'experience_impression', {
                        exp_variant_string: `next-${type}-${experimentGroup}`,
                    });
                    sendGAEvent('event', GA_EVENT_NAME, {
                        event_category: GA_EVENT_CATEGORY,
                        event_label: type,
                    });
                }
            },
            {
                root: null, // viewport
                rootMargin: '0px', // no margin
                threshold: 1, // 50% of target visible
            },
        );

        observer.observe(current);
        return () => {
            observer.unobserve(current);
        };
    }, [hasTriggered, experimentGroup, type]);

    return <span ref={ref} data-experiment={type} data-experiment-group={experimentGroup} />;
};
